import general from "../../../general";
import React from "react";
import { CheckCircleTwoTone, SyncOutlined } from "@ant-design/icons";
import { useInterval, useMount, useReactive } from "ahooks";
import URI from "urijs";
import useActiveState from "../../../lib/useActiveState";
import Dialog from "../../../lib/Dialog";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Getter from "./Getter";
import Guide from "./Guide";
import Reserve from "./Reserve";
import Tips from "./Tips";
import { prod } from "../../../config";

const { aplus_queue } = window;

const Main = ({ game, close }) => {
  const [udid] = useActiveState("udid");
  const data = useReactive({
    udidGetter: undefined,
    interval: undefined,
    state: undefined,
    reserve: undefined,
    tips: undefined,
    url: undefined,
  });
  useInterval(
    () => {
      let url = game.game_url.super_ios_url;
      if (prod || url?.indexOf("app-api2.9917.cn") >= 0) {
        url = url.replace("http://", "https://");
      }
      general.axios
        .get(url, { timeout: 300 * 1000 })
        .then(({ data: result }) => {
          let { status, data: resData } = result;
          if (!status.succeed) {
            if (status.error_desc?.indexOf("未生效")) {
              data.reserve = true;
              data.interval = undefined;
              return;
            }
            Dialog.error(status.error_desc);
          } else {
            data.state = resData.status;
            if (resData.status === 1) {
              aplus_queue.push({
                action: "aplus.record",
                arguments: [
                  "download",
                  "download",
                  {
                    type: "vip",
                    state: "success",
                    gameId: game.game_id,
                    agent: localStorage.getItem("agent"),
                    username: localStorage.getItem("username"),
                  },
                ],
              });

              data.interval = undefined;
              if (navigator.userAgent == "app") {
                window.location.href = URI("app://browser")
                  .addQuery("url", resData.url)
                  .toString();
              } else {
                window.location.href = resData.url;
              }
              if (!resData.super) {
                data.tips = true;
                data.url = resData.url;
                return;
              }
              close();
            }
          }
        })
        .catch((error) => console.error(error));
    },
    data.interval,
    { immediate: true }
  );
  useMount(() => {
    if (udid) {
      data.interval = 3000;
      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "download",
          "download",
          {
            type: "vip",
            state: "start",
            gameId: game.game_id,
            agent: localStorage.getItem("agent"),
            username: localStorage.getItem("username"),
          },
        ],
      });
    }
  });

  return (
    <>
      {udid ? (
        <>
          {data.interval ? (
            <>
              <div className="game-super">
                <div className="gs-container">
                  <div
                    className={"item " + (data.state === 0 ? " ing" : " ed")}
                  >
                    <CheckCircleTwoTone
                      className="icon normal"
                      twoToneColor="#cccccc"
                    />
                    <SyncOutlined spin className="icon ing" />
                    <CheckCircleTwoTone className="icon ed" />
                  </div>
                  <div
                    className={
                      "item " +
                      (data.state === -3 ? " ing" : " ") +
                      (data.state === 1 || data.state === -2 ? " ed" : " ")
                    }
                  >
                    <CheckCircleTwoTone
                      className="icon normal"
                      twoToneColor="#cccccc"
                    />
                    <SyncOutlined spin className="icon ing" />
                    <CheckCircleTwoTone className="icon ed" />
                  </div>
                  <div
                    className={
                      "item " +
                      (data.state === -2 ? " ing" : " ") +
                      (data.state === 1 ? " ed" : " ")
                    }
                  >
                    <CheckCircleTwoTone
                      className="icon normal"
                      twoToneColor="#cccccc"
                    />
                    <SyncOutlined spin className="icon ing" />
                    <CheckCircleTwoTone className="icon ed" />
                  </div>
                  <CopyToClipboard
                    text={udid}
                    onCopy={() => Dialog.info("已复制")}
                  >
                    <div className="udid">
                      <div className="udidd">UDID：{udid}</div>
                      <div className="btn">复制</div>
                    </div>
                  </CopyToClipboard>
                  <div className="tips">请不要离开此页面并保持屏幕常亮</div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Getter close={close} />
        </>
      )}
      {data.reserve ? (
        <>
          <Reserve game={game} close={close} />
        </>
      ) : null}
      {data.tips ? (
        <>
          <Tips url={data.url} close={close} />
        </>
      ) : null}
    </>
  );
};

export default (props) => {
  const [udid] = useActiveState("udid");

  return (
    <>
      {udid && (
        <Guide>
          <Main {...props} />
        </Guide>
      )}
    </>
  );
};
