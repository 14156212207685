import React, { useEffect, useState, useRef } from "react";
import "swiper/swiper-bundle.css";
import "video-react/dist/video-react.css";
import "../css/css.css";
import { navigationRouter, screenRouter } from "../router";
import URI from "urijs";
import general, { redux, spm } from "./../general";
import { NavLink, NavView } from "../lib/Navigation";
import {
  useEventListener,
  useLocalStorageState,
  useMount,
  useTimeout,
} from "ahooks";
import ActiveContext from "../lib/ActiveContext";
import useActiveState from "../lib/useActiveState";
import $ from "jquery";
import { state } from "../lib/reducer";
import { useSelector } from "react-redux";
import Dialog from "../lib/Dialog";
import Image from "../lib/Image";
import { Link, useHistory, useLocation } from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import dayjs from "dayjs";
import MobileDetect from "mobile-detect";
import { prod } from "../config";
import JSEncrypt from "jsencrypt";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import CryptoJS from "crypto-js";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

const mobileDetect = new MobileDetect(navigator.userAgent);

const desktopTipsBranch = (
  (navigator.userAgent.match(/(edgios)/i) || {})[0] || ""
).toLowerCase();

let prev = null;

// 入口文件
export default () => {
  let history = useHistory();
  let location = useLocation();

  console.log("入口文件--------");

  useEffect(() => (general.history = history), [history]);
  useEffect(() => (general.location = location), [location]);

  let [params, setParams] = useUrlState({}, { navigateMode: "replace" });

  const [standalone, setStandalone] = useLocalStorageState("standalone");
  useMount(() => {
    if (params.standalone) {
      setStandalone(params.standalone);
    }

    if (params.bd_vid) {
      localStorage.setItem("bd_vid", params.bd_vid);
    }
  });

  const [active, setActive] = useLocalStorageState("active", {
    defaultValue: {},
  });
  const { aplus_queue } = window;

  const { theme } = useSelector(state);
  let [agent, setAgent] = useActiveState("agent");
  let [udid, setUdid] = useActiveState("udid");
  let [key, setKey] = useActiveState("key");
  let [invalidKeys, setInvalidKeys] = useLocalStorageState("invalid-keys", {
    defaultValue: [],
  });
  let [app, setApp] = useActiveState("app");
  useMount(async () => {
    if (params.agent) {
      setAgent((agent = params.agent));
      localStorage.setItem("agent", params.agent);
      console.log("agenttttttttttttttttttttt", localStorage.getItem("agent"));
    }
    console.log("设置udidbbbbbbbbb--------------", params.udid);
    if (params.udid) {
      setUdid(params.udid);
    }

    if (params.key) {
      if (!invalidKeys.includes(params.key)) {
        setKey((key = params.key));
      }
    } else {
      setKey((key = ""));
    }
    console.log(key);

    if (!app.brand) {
      if (params.brand) {
        app.brand = params.brand;
      }
      let tmp = {
        "9917游戏": "9917",
        BT玩: "btwan",
        嗨玩游戏: "hiwan",
        "97游戏盒": "97",
        泡泡熊: "zc",
        大碗游戏盒: "dawan",
        疯玩游戏: "fw",
      };
      if (app.appname && tmp[app.appname]) {
        app.brand = tmp[app.appname];
      }
    } else {
      if (params.brand) {
        app.brand = params.brand;
      }
    }
    app = params;
    setApp(params);

    if (!key) {
      await general.axios
        .post("/base/newindex/setKeyUdid", {
          agent,
          brand: params.brand,
          appname: params.appname,
          appicon: params.appicon,
          applogo: params.applogo,
          flash_screen: params.flash_screen,
        })
        .then((res) => {
          let { status, data } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setKey((key = data.key));
          setParams((params = { ...params, agent: agent, key: data.key }));
          if (
            params.appname ||
            params.appicon ||
            params.applogo ||
            params.flash_screen
          ) {
            setParams(
              (params = {
                ...params,
                appname: null,
                appicon: null,
                applogo: null,
                flash_screen: null,
              })
            );
          }
        })
        .catch((error) => Dialog.error("setKeyUdid " + error.message));
    }

    if (key && app.key !== key) {
      general.axios
        .post("/base/newindex/getKeyUdid", {
          agent,
          key,
          brand: params.brand,
          not_check_ua: true,
        })
        .then((res) => {
          let { status, data } = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (data.key && data.key !== key) {
            if (!invalidKeys.includes(app.key)) {
              setInvalidKeys([...invalidKeys, key]);
            }
            setKey((key = data.key));
            setParams({ key });
          }
          for (const key of ["appname", "appicon", "applogo", "flash_screen"]) {
            if (app[key + "_lock"]) {
              delete data[key];
            }
          }
          setApp((app = { ...app, ...data }));

          if (data.brand) {
            setParams((params = { ...params, brand: data.brand }));
          }
          if (
            params.appname ||
            params.appicon ||
            params.applogo ||
            params.flash_screen
          ) {
            const ts = [];
            for (const key of [
              "appname",
              "appicon",
              "applogo",
              "flash_screen",
            ]) {
              if (params[key]) {
                ts[key] = params[key];
                ts[key + "_lock"] = params[key];
              }
            }
            setApp((app = { ...app, ...ts }));
            setParams(
              (params = {
                ...params,
                appname: null,
                appicon: null,
                applogo: null,
                flash_screen: null,
              })
            );
          }
        })
        .catch((err) => Dialog.error("getKeyUdid " + err.message));
    }

    if (key) {
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["openid", key],
      });
    }
  });
  const urlLogin = () => {
    if (
      !localStorage.getItem("logout") &&
      params.userid &&
      params.username &&
      params.token
    ) {
      localStorage.setItem("user_id", params.userid);
      localStorage.setItem("username", params.username);
      localStorage.setItem("token", params.token);
    }
  };
  useMount(urlLogin);

  useEffect(() => {
    history.action !== "POP" && urlLogin();
  }, [params, history.action]);

  useEffect(() => {
    params.udid && setUdid(params.udid);
  }, [params.udid]);

  useEffect(() => {
    if (app.appname) {
      document.title = app.appname;
      $("meta[name=apple-mobile-web-app-title]").attr("content", app.appname);
    }
    if (app.appicon) {
      if (!app.brand || app.brand == "9917") {
        $("link[id=appleTouchIcon]").attr(
          "href",
          "https://filestorage.9917.cn/agent-material/bc8f/icon.png"
        );
      } else {
        $("link[id=appleTouchIcon]").attr("href", app.appicon);
      }
    }
    if (app.flash_screen) {
      console.log("app.flash_screen-------------------", app.flash_screen);
      [
        { width: 2048, height: 2732 },
        { width: 1668, height: 2388 },
        { width: 1536, height: 2048 },
        { width: 1668, height: 2224 },
        { width: 1620, height: 2160 },
        { width: 1284, height: 2778 },
        { width: 1170, height: 2532 },
        { width: 1125, height: 2436 },
        { width: 1242, height: 2688 },
        { width: 828, height: 1792 },
        { width: 1242, height: 2208 },
        { width: 750, height: 1334 },
        { width: 640, height: 1136 },
      ].forEach(({ width, height }) => {
        $('link[id="' + (width + "-" + height) + '"]').attr(
          "href",
          app.flash_screen +
            (width === 1170 && height === 2532
              ? "!iphone12pro"
              : "!iphone_" + width + "x" + height)
        );
      });
    }
  }, [app]);

  const encodeAndHash = (value) => {
    // Base64 编码
    const base64Encoded = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(value)
    );

    // MD5 加密
    const md5Hash = CryptoJS.MD5(base64Encoded).toString(CryptoJS.enc.Hex);

    return md5Hash;
  };

  useEffect(() => {
    let time = new Date().getTime() + "";
    let sign = `991702{"appid":"991702","name":"1732609027","timestamp":"${time}"}586C3C60E2F21EB62B2EEB5A1E1DF845`;

    general.axios
      .post("/get/sts", {
        appid: "991702",
        timestamp: time,
        sign: encodeAndHash(sign),
        name: "1732609027",
      })
      .then((res) => {
        console.log("get/sts-------------", res);

        if (res.data.data.accessKeyId) {
          let umModule;
          let deviceToken;
          ALIYUN_FP.use("um", (state, um) => {
            console.log(state, um);

            if (state === "loaded") {
              umModule = um;
              um.init({
                appKey: res.data.data.accessKeyId,
                appName: "webbox",
                //endpoints : ['https://cloudauth-device.aliyuncs.com']
              });
            }
          });

          setTimeout(() => {
            // 后续 注册 时，要将deviceToken传给后端
            deviceToken = umModule.getToken();
            localStorage.setItem("deviceToken", deviceToken);
            console.log("deviceToken----------------------", deviceToken);
          }, 3000);
        }
      })
      .catch((error) => console.log("sts访问失败啦~~~~~~~~~~~~ " + error));
  }, []);

  const [config, setConfig] = useActiveState("config");
  useMount(() => {
    general.axios
      .post("/base/common/getConfig")
      .then((res) => {
        let { status, data } = res.data;
        if (status.succeed) {
          setConfig({ ...config, ...data });
        }
      })
      .catch((error) => Dialog.error("getConfig " + error.message));
  });
  useEffect(() => {
    if (
      config.udid_usable !== undefined &&
      !config.udid_usable &&
      localStorage.getItem("udid_usable")
    ) {
      localStorage.setItem("udid_usable", "");
    }
  }, [config.udid_usable]);

  const [navs, setNavs] = useState([]);
  const [action, setAction] = useState([]);
  const handleNavs = () => {
    if (general.callbacking) {
      return;
    }
    let uri = URI(window.location.hash.substring(1));
    if (uri.pathname() === "/iframe") {
      let query = uri.query(true);
      if (query.url) {
        let iframeUri = URI(query.url);
        let iframeQuery = iframeUri.query(true);
        if (
          iframeQuery.username !== localStorage.getItem("username") ||
          iframeQuery.token !== localStorage.getItem("token")
        ) {
          history.replace(
            uri
              .setQuery(
                "url",
                iframeUri
                  .setQuery("username", localStorage.getItem("username"))
                  .setQuery("token", localStorage.getItem("token"))
                  .toString()
              )
              .toString()
          );
          uri = URI(window.location.hash.substring(1));
        }
      }
    }
    setNavs((_navs) => {
      let navs = [..._navs];
      let uri2 = URI(uri.search()).removeQuery("callback");
      if (
        uri.pathname().startsWith("/search") ||
        uri.pathname().startsWith("/gift/search")
      ) {
        uri2.removeQuery("kw");
      }
      let url = uri.pathname() + uri2.toString();
      let pathname = uri.pathname();
      // console.log(general.callbacking, general.callback);
      let router = navigationRouter.find((item) => item.path === pathname);
      if (router) {
        navs.reverse();
        let index = navs.findIndex((item) => item.url === url);
        navs.reverse();
        if (index > 0) {
          while (navs[navs.length - 1].url !== url) {
            navs.pop();
          }
        }
        if (index === -1) {
          navs.push({ ...router, query: uri.query(true), url });
        }
      } else {
        navs = [];
      }
      navs = navs.map((item, index, items) => {
        item.index = 700 + index + 1;
        item.active = items.length - 1 === index;
        item.action = items.length - 1 === index ? history.action : null;
        return item;
      });
      console.log("navs-------------------", [...navs]);
      return [...navs];
    });
  };
  useEffect(() => handleNavs(), [history, location]);

  useMount(() => {
    redux.subscribe(() => {
      if (redux.getState().url) {
        console.log("location", { ...location }, window.location.href);
        handleNavs();
      }
    });
    redux.subscribe(() => {
      let screen = redux.getState().screenRouter?.screen;
      if (screen) {
        history.replace(screen.replace("/", "/screen-"));
      }
    });
  });

  const encryptWithPublicKey = (message, publicKey) => {
    // 创建 JSEncrypt 实例
    const encrypt = new JSEncrypt();

    // 设置公钥
    encrypt.setPublicKey(publicKey);

    // 加密消息
    const encrypted = encrypt.encrypt(message);

    return encrypted;
  };

  // 示例公钥 (通常从服务器获取)
  const publicKey = `-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAu5phb2FQQiuZSlSxI09q4Mc3LIpuUTZP5+EBe480c9/zDkVgPmuIQcBMPbi5NC5yxpYOCUZkLjC8VDzMqDgS89c6hC3qY0btRBxON7gO9pk4ijUEa2xlvnefvYgXMpks13FT5To852iguQbW2k53DHD6Efsvbvh/Nl1kRXhYGPw3XWAQeDNS9xFESpx+9r4KWcm8D/R2fnv5w1P/UvcNrYVDMTGkEmOGnVl96WZBPcHuZRrRcXcbzTE3TMzabQAdqR+FDPRPWpK5F/WUuWWbKL+MS3dvREdOxYPgIfvyPm4ztLp0I+v06/fZvUOembo4Bokj0AusbYKqUkp3s6El11xmHyaRJULh9PYJ9xfte1oihnRpU+HenVUfHBQBy8XBzHkhUsVfKV+WGaARYRQ2efSsTojji8WBXpt63kkkltcgSZJvQ9gPcSTAz3xRkX6tRTEA61AsbT1ua+uJlFWa97P3xHd+SGcOYsOjSbnpB/WGxBMs7dX2FEuevaEEgyJr+d336jhzK/6Mc6eaIKlPPZyZfXUH/KK3YIllDSx2WbAHibTZfetp/76KL+QBLLv2QFGwjWZKxCZ/M2zRvRGL1wLLuw5DAr8LiodEt1YABpVO28YNwf87zpt4XZ7bv4ubNP8NYONSLAB7YqPUnG58UQ8I3Yvw2k3Nmos/Cs/vFasCAwEAAQ==-----END PUBLIC KEY-----`;

  const callFunc = async () => {
    const timeCall = new Date().getTime();
    console.log("没有bd_vid激活-----------");

    let objCall = {
      time: timeCall,
      agent: localStorage.getItem("agent"),
    };
    let strCall = btoa(JSON.stringify(objCall));

    // 调用加密函数
    const encryptedMessageCall = encryptWithPublicKey(strCall, publicKey);

    await general.axios
      .post("/tgm/is_callback", {
        time: timeCall,
        agent: localStorage.getItem("agent"),
        sign: encryptedMessageCall,
      })
      .then(async (res) => {
        console.log(res);

        if (res?.data?.data?.is_bind) {
          const time = new Date().getTime();
          console.log("没有bd_vid激活-----------");

          let obj = {
            account_type: "baidu_xs",
            package_name: "com.xt.xuantian",
            os_type: "ios",
            MODEL: "2304FPN6DC",
            time,
            vid: localStorage.getItem("bd_vid"),
            agent: localStorage.getItem("agent"),
          };
          let str = btoa(JSON.stringify(obj));

          // 调用加密函数
          const encryptedMessage = encryptWithPublicKey(str, publicKey);

          await general.axios
            .post("/tgm/impute_bind", {
              account_type: "baidu_xs",
              package_name: "com.xt.xuantian",
              os_type: "ios",
              MODEL: "2304FPN6DC",
              time,
              vid: localStorage.getItem("bd_vid"),
              sign: encryptedMessage,
              agent: localStorage.getItem("agent"),
            })
            .then((res) => {
              console.log(res);

              if (res?.data?.data?.uuid) {
                localStorage.setItem("tgm_uuid", res.data.data.uuid);
              }
            })
            .catch((error) => console.log("media-point " + error));
        } else {
          localStorage.setItem("tgm_uuid", "");
        }
      })
      .catch((error) => console.log("media-point " + error));
  };

  useTimeout(async () => {
    if (
      !active.time ||
      dayjs(active.time).format("YYYY-MM-DD") !== dayjs().format("YYYY-MM-DD")
    ) {
      console.log("--activate.time--");
      setActive((active) => ({ ...active, time: dayjs().valueOf() }));
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          { is_auto: false },
          {
            page: "index",
            isApp: !!window.navigator.standalone,
            agent,
          },
        ],
      });
      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "enter",
          "enter",
          {
            isApp: !!window.navigator.standalone,
            agent,
          },
        ],
      });
    }

    if ((window.navigator.standalone || standalone) && !active.activate) {
      console.log("-----------------------------------activate.activate--");
      setActive((active) => ({ ...active, activate: dayjs().valueOf() }));

      if (!localStorage.getItem("tgm_uuid")) {
        await callFunc();
      }

      if (localStorage.getItem("tgm_uuid")) {
        activateTrigger();
        getDyGameId();
      }

      aplus_queue.push({
        action: "aplus.record",
        arguments: [
          "activate",
          "activate",
          {
            agent,
          },
        ],
      });

      if (params?.mid) {
        history.push("/game?mid=" + params.mid);
      }

      // general.axios.post("/home/ocpc/activation");
    }
  }, 1527);

  const activateTrigger = () => {
    let objBind = {
      type: "activate",
      uuid: localStorage.getItem("tgm_uuid"),
      time: new Date().getTime(),
    };

    let strBind = btoa(JSON.stringify(objBind));

    // 调用加密函数
    const encryptedBindMessage = encryptWithPublicKey(strBind, publicKey);

    general.axios
      .post("/tgm/trigger/warning", {
        type: "activate",
        uuid: localStorage.getItem("tgm_uuid"),
        time: new Date().getTime(),
        sign: encryptedBindMessage,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => console.log("激活行为回传--- " + error));
  };

  const getDyGameId = () => {
    let objBind = {
      uuid: localStorage.getItem("tgm_uuid"),
      time: new Date().getTime(),
    };

    let strBind = btoa(JSON.stringify(objBind));

    // 调用加密函数
    const encryptedBindMessage = encryptWithPublicKey(strBind, publicKey);

    general.axios
      .post("/tgm/get_game", {
        uuid: localStorage.getItem("tgm_uuid"),
        time: new Date().getTime(),
        sign: encryptedBindMessage,
      })
      .then((res) => {
        console.log(res);

        if (res?.data?.data?.game_id) {
          history.push("/game?mid=" + res.data.data.game_id);
        }
      })
      .catch((error) => console.log("激活行为回传--- " + error));
  };

  const [activity, setActivity] = useState(true);
  const [toSafariCopy, setToSafariCopy] = useState();

  const [safeArea, setSafeArea] = useState();
  useEffect(() => {
    setSafeArea(
      parseInt($(".safeareatest").css("padding-top")) > 20 ||
        parseInt($(".safeareatest").css("padding-bottom")) > 20
    );
  });

  function isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEventListener("message", (e) => {
    console.log(e);
    if (e.data === "undefined" || !isJSONString(e.data)) {
      return;
    }

    const message = JSON.parse(e.data);
    if (message.type === "history") {
      let url = message.url;
      console.log("url999999999999999999-----", url);

      if (url === "back") {
        history.goBack();
        return;
      }
      if (url.startsWith("box9917://")) {
        url = url.replace("box9917://", "");
      }
      let uri = URI(url);
      console.log("message url scheme", uri);
      if (!uri.scheme()) {
        if (url.startsWith("/login")) {
          if (uri?._parts?.query) {
            history.push(
              URI("/login")
                .addQuery("back", 1)
                .addQuery(
                  "callback",
                  location.pathname + location.search + location.hash
                )
                .toString()
            );
          } else {
            history.push(
              URI("/login")
                .addQuery(
                  "callback",
                  location.pathname + location.search + location.hash
                )
                .toString()
            );
          }
        } else {
          history.push(url);
        }
      } else {
        console.log("message url", url);
        window.location.href = url;
      }
    } else if (message.type === "passBack") {
      console.log("H5页面行为回传---------------", message);
    } else if (message.type === "payUrl") {
      console.log("云挂机H5回传---------------", message);

      window.location.href = message.url;
    } else if (
      message.type == "exitCloudApp" ||
      message.type == "leaveHanging"
    ) {
      console.log(message);
    }
  });

  useEffect(
    function () {
      let url = window.location.hash.substring(1);
      let uri = URI(url);
      if (
        localStorage.getItem(`browse_${url}`) !==
        dayjs(active.time).format("YYYY-MM-DD")
      ) {
        localStorage.setItem(
          `browse_${url}`,
          dayjs(active.time).format("YYYY-MM-DD")
        );
        let data = {
          isApp: !!window.navigator.standalone,
          agent,
          prevPath: prev?.path,
          prevUrl: prev?.url,
          currPath: uri.pathname(),
          currUrl: url,
        };
        // console.log(data);
        aplus_queue.push({
          action: "aplus.record",
          arguments: ["browse", "browse", data],
        });
      }
      prev = {
        path: uri.pathname(),
        url: url,
      };
    },
    [location]
  );

  let [safeAreaInsetTop, setSafeAreaInsetTop] = useState();
  useMount(() => {
    if (params["safe-area-inset-top"]) {
      setSafeAreaInsetTop(
        (safeAreaInsetTop = params["safe-area-inset-top"] * 1)
      );
      if (safeAreaInsetTop) {
        $("head").append(
          "<style>:root { --safe-area-inset-top: " +
            safeAreaInsetTop +
            "px; }</style>"
        );
      }
    }
  });

  const [guide, setGuide] = useActiveState("guide");
  const [guided] = useActiveState("guided");
  useMount(() => {
    if (!guided && navigator.standalone) {
      setGuide("guide");
    }
  });

  const [tikShow, setTikShow] = useActiveState("tikShow");
  useMount(() => {
    if (navigator.userAgent == "uniapp") {
      let x;
      document.addEventListener("touchstart", (e) => {
        x = e.changedTouches[0].pageX;
      });
      document.addEventListener("touchend", (e) => {
        if (x !== undefined && x < 23 && e.changedTouches[0].pageX - x > 23) {
          // console.log('goBack!!!');
          history.goBack();
        }
        x = undefined;
      });
    }

    // 抖音审核
    general.axios
      .post("/base/apphome/agentDisplay")
      .then((res) => {
        let { status, data } = res.data;
        if (status.succeed) {
          console.log("抖音审核是否显示----", data);

          setTikShow(data);
        }
      })
      .catch((error) => Dialog.error("getConfig " + error.message));
  });

  SwiperCore.use([Pagination]);

  const swiperRef = useRef(null);
  const swiperLinkRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(1);
  useEffect(() => {
    // 延迟初始化Swiper，确保容器尺寸完全加载
    setTimeout(() => {
      if (swiperLinkRef.current && swiperLinkRef.current.swiper) {
        swiperLinkRef.current.swiper.update();
      }

      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.update();
      }
    }, 300); // 延迟300ms进行更新，确保容器已加载完毕
  }, []); // 仅在组件首次加载时执行

  let userAgent = navigator.userAgent;
  let isIos = userAgent.match(/iphone os|ipad|mac os/i);

  const [isSafari, setIsSafari] = useState(isIos);
  useEffect(() => {
    if (isIos) {
      // 检测是否是特定浏览器，排除 Safari
      if (
        userAgent.match(
          /baidu|mqqbrowser|crios|fxios|edgios|ucbrowser|micromessenger/i
        ) ||
        userAgent.match(/mobile\/[^\s]+ version\/[^\s]+ safari\/[^\s]+/i)
      ) {
        setIsSafari(false);
      }
    }
  }, []);

  const copyLink = window.location.href;

  return (
    <>
      <div
        className={
          "root" +
          (safeArea ? " root-hassafearea" : " root-nosafearea") +
          " theme-" +
          theme +
          (safeAreaInsetTop ? " var-safe-area" : "")
        }
      >
        <div className="safeareatest" />

        {/* 
        window.navigator.standalone 可以确定用户目前访问是否为 书签版访问
        iOS上的Safari浏览器可以让Web应用程序全屏显示，以取得类似本地应用的显示效果。但是这需要用户把Web应用程序的图标添加到主屏幕才可以。
        作为开发者，为了更好的显示效果，我们可能希望自己开发的Web应用程序在非全屏状态下运行时提示用户把Web应用程序的图标添加到主屏幕。

        要检测Web应用程序当前是否运行在全屏状态，只要检测window.navigator.standalone是否为true就可以了，如果这个属性为true则表示Web应用程序
        当前运行在全屏状态，否则运行在非全屏状态。检测到Web应用程序运行在非全屏状态时就可以提示用户把Web应用程序的图标添加到主屏幕 */}
        {/* 
        未加书签  生产环境  手机或者ipad  域名不为本地 
        有活动展示活动  
        没活动  若不是Safari浏览器---展示复制打开Safari弹窗  若是Safari---展示加书签步骤图
        */}

        {!window.navigator.standalone &&
        prod &&
        (mobileDetect.is("iPhone") ||
          mobileDetect.is("iPad") ||
          navigator.userAgent == "app") &&
        window.location.hostname !== "localhost" &&
        !window.location.hostname.startsWith("192.168") &&
        navs.length === 0 &&
        !standalone ? (
          <>
            {!isSafari ? (
              <div className="swiper-tips-container">
                <div
                  className="swiper-tips-main swiper-link-tips-main"
                  style={{ position: "relative" }}
                >
                  <Swiper
                    ref={swiperLinkRef}
                    spaceBetween={10} // 图片之间的间距
                    slidesPerView={1} // 每次展示一个图片
                    loop // 让轮播图循环
                    autoplay={{
                      delay: 2000, // 自动播放的延迟时间（毫秒）
                      disableOnInteraction: true, // 用户交互后不禁用自动播放
                    }}
                    initialSlide={0}
                  >
                    <SwiperSlide>
                      <img src="/resources/gifts/link01.gif" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/gifts/link02.gif" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src="/resources/gifts/link03.gif" alt="" />
                    </SwiperSlide>
                  </Swiper>

                  <div className="link-swiper-tips">
                    <div className="link-tips">
                      请使用
                      <img
                        className="link-tips-img"
                        src="/resources/safari.png"
                        alt=""
                      />
                      <span style={{ color: "#2E7BE5" }}>Safari</span>
                      浏览器打开链接
                    </div>
                    <p className="link-tips">安装到桌面即可体验游戏</p>

                    <CopyToClipboard
                      text={copyLink}
                      onCopy={() => Dialog.info("已复制")}
                    >
                      <div className="link-copy">复制链接</div>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {params.mc ? (
                  <div className="mobileconfig-tips">
                    <div className="container">
                      <img src="/resources/mobileconfig.png" alt="" />
                      <a
                        href={URI("//app.9917.cn/webbox.php?")
                          .addQuery("url", window.location.href)
                          .addQuery("name", app.appname)
                          .addQuery("icon", app.appicon)}
                        onClick={() => {
                          setTimeout(() => {
                            window.location.href =
                              "https://app.9917.cn/cert/embedded.mobileprovision";
                          }, 2500);
                        }}
                      >
                        点击安装
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="swiper-tips-container">
                    <div className="swiper-tips-main">
                      <div className="swiper-tips-title">
                        <div className="swiper-tips-img">
                          <Image src={app.appicon} />
                        </div>
                        <div>
                          <p className="swiper-tips-name">
                            添加应用到桌面
                            <span>教程如下↓</span>
                          </p>
                          <p className="swiper-tips-font">
                            添加<span> {app.appname} </span>
                            到手机桌面即可体验游戏啦
                          </p>
                        </div>
                      </div>

                      {app.brand == "9917" ? (
                        <Swiper
                          ref={swiperRef}
                          spaceBetween={10} // 图片之间的间距
                          slidesPerView={1} // 每次展示一个图片
                          pagination={{ clickable: true }}
                          loop // 让轮播图循环
                          autoplay={{
                            delay: 2500, // 自动播放的延迟时间（毫秒）
                            disableOnInteraction: true, // 用户交互后不禁用自动播放
                          }}
                          initialSlide={0}
                          onSlideChange={(swiper) => {
                            const videos =
                              swiperRef.current.querySelectorAll("video");

                            // 暂停所有视频
                            videos.forEach((video) => {
                              video.pause();
                              video.currentTime = 0;
                            });

                            // 获取当前活动幻灯片的索引
                            const activeIndex = swiper.activeIndex;
                            const activeVideo = videos[activeIndex];

                            // 如果当前幻灯片有视频，播放它
                            if (activeVideo) {
                              activeVideo.play();
                            }
                          }}
                        >
                          <SwiperSlide>
                            <video
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "20px",
                              }}
                              autoPlay
                              muted
                              controls={false}
                              webkit-playsinline="true"
                              playsInline={true}
                              src="/resources/gifts/video01.mp4"
                              type="video/mp4"
                            ></video>
                          </SwiperSlide>
                          <SwiperSlide>
                            <video
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "20px",
                              }}
                              autoPlay
                              muted
                              controls={false}
                              webkit-playsinline="true"
                              playsInline={true}
                              src="/resources/gifts/video02.mp4"
                              type="video/mp4"
                            ></video>
                          </SwiperSlide>
                          <SwiperSlide>
                            <video
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "20px",
                              }}
                              autoPlay
                              muted
                              controls={false}
                              webkit-playsinline="true"
                              playsInline={true}
                              src="/resources/gifts/video03.mp4"
                              type="video/mp4"
                            ></video>
                          </SwiperSlide>
                          <SwiperSlide>
                            <video
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "20px",
                              }}
                              autoPlay
                              muted
                              controls={false}
                              webkit-playsinline="true"
                              playsInline={true}
                              src="/resources/gifts/video04.mp4"
                              type="video/mp4"
                            ></video>
                          </SwiperSlide>
                        </Swiper>
                      ) : app.brand == "seo" ? (
                        <Swiper
                          ref={swiperRef}
                          spaceBetween={10} // 图片之间的间距
                          slidesPerView={1} // 每次展示一个图片
                          pagination={{ clickable: true }}
                          loop // 让轮播图循环
                          autoplay={{
                            delay: 4500, // 自动播放的延迟时间（毫秒）
                            disableOnInteraction: true, // 用户交互后不禁用自动播放
                          }}
                          initialSlide={0}
                          onSlideChange={(swiper) => {
                            // 获取当前活动幻灯片的索引
                            const activeI = swiper.activeIndex;
                            setActiveIndex(activeI);
                          }}
                        >
                          <SwiperSlide>
                            <img src="/resources/gifts/tips01.gif" alt="" />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img src="/resources/gifts/tips02.gif" alt="" />
                          </SwiperSlide>
                          <SwiperSlide>
                            <div style={{ position: "relative" }}>
                              <img
                                src="/resources/gifts/tips03-common.gif"
                                alt=""
                              />

                              <div className="tips-third-main">
                                <div className="tips-third-top">
                                  <div className="icon">
                                    <Image src={app.appicon} alt="" />
                                  </div>
                                  <div className="info">
                                    <div className="name">{app.appname}</div>
                                    <div className="url">
                                      {window.location.href}
                                    </div>
                                  </div>
                                </div>
                                <p className="tips-font">
                                  一个图标将会添加到你的主屏幕，以便你快速访问此网站
                                </p>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div style={{ position: "relative" }}>
                              <img src="/resources/gifts/back.png" alt="" />

                              <div
                                className="desktop-icon"
                                style={{
                                  animation:
                                    activeIndex === 4
                                      ? "shrink 2s ease-out"
                                      : "none",
                                }}
                              >
                                <div className="desktop-icon-img">
                                  <Image src={app.appicon} alt="" />
                                </div>

                                <div className="name">{app.appname}</div>
                              </div>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      ) : (
                        <Swiper
                          ref={swiperRef}
                          spaceBetween={10} // 图片之间的间距
                          slidesPerView={1} // 每次展示一个图片
                          pagination={{ clickable: true }}
                          loop // 让轮播图循环
                          autoplay={{
                            delay: 4500, // 自动播放的延迟时间（毫秒）
                            disableOnInteraction: true, // 用户交互后不禁用自动播放
                          }}
                          initialSlide={0}
                        >
                          <SwiperSlide>
                            <img src="/resources/gifts/tips01.gif" alt="" />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img src="/resources/gifts/tips02.gif" alt="" />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={`/resources/gifts/${
                                "tips03-" + app.brand || "9917"
                              }.gif`}
                              alt=""
                            />
                          </SwiperSlide>
                        </Swiper>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : null}

        {((prod && (window.navigator.standalone || standalone)) ||
          !prod ||
          window.location.hostname == "localhost" ||
          window.location.hostname.startsWith("192.168")) && (
          <div
            className={"screen-index " + (navs.length === 0 ? "active" : "")}
          >
            <div className="screen-body">
              {screenRouter.map((item) => (
                <div
                  className={
                    "screen-body-item " +
                    item.id +
                    " " +
                    (location.pathname === item.path ? "active" : "")
                  }
                  key={item.id}
                >
                  <ActiveContext.Provider
                    value={navs.length === 0 && location.pathname === item.path}
                  >
                    <item.component
                      active={
                        navs.length === 0 && location.pathname === item.path
                      }
                      action={action}
                    />
                  </ActiveContext.Provider>
                </div>
              ))}
            </div>
            <div className="screen-menu width" key={location.pathname}>
              <div className="screen-menu-container">
                {screenRouter.map((item) => {
                  let Tag = item.user ? NavLink : Link;
                  return (
                    <Tag
                      key={item.id}
                      to={item.path}
                      replace
                      className={
                        "screen-menu-item " +
                        (location.pathname === item.path ? "active" : "")
                      }
                    >
                      <div
                        className="icon icon-dark"
                        style={{
                          backgroundImage:
                            "url(/resources/screen/" + item.id + "-dark.png)",
                        }}
                      />
                      <div
                        className="icon icon-highlight"
                        style={{
                          backgroundImage:
                            "url(/resources/screen/" +
                            item.id +
                            "-highlight.png)",
                        }}
                      />
                      <div
                        className="icon icon-white"
                        style={{
                          backgroundImage:
                            "url(/resources/screen/" + item.id + "-white.png)",
                        }}
                      />
                      <div className="text">{item.title}</div>
                    </Tag>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {navs.map((nav, index) => (
          <NavView key={index} {...nav} />
        ))}
      </div>
      {/*{guide ? <Guide/> : null}*/}
      {/*<Getted/>*/}
    </>
  );
};
