import useActiveState from "../../../lib/useActiveState";
import Getter from "./Getter";
import { useState } from "react";

import general from "../../../general";
import { useRequest } from "ahooks";
import _ from "lodash";

export default ({ reject, children, getter: DiyGetter }) => {
  const [config, setConfig] = useActiveState("config");
  let [udid, setUdid] = useActiveState("udid");

  const [dep, setDep] = useState(_.random(132, 277));
  const retry = () => {
    setDep(_.random(132, 277));
  };
  const { loading } = useRequest(
    async () => {
      if (udid) {
        return;
      }

      if (udid) {
        await general.axios
          .post("/base/common/getConfig")
          .then(({ data: resData }) => {
            let { status, data } = resData;
            if (status.succeed) {
              setConfig({ ...config, udid_usable: data.udid_usable });
            }
          })
          .catch((error) => console.error(error));
      }
    },
    { refreshDeps: [dep] }
  );

  return (
    <>
      {!loading ? (
        <>
          {udid ? (
            children
          ) : (
            <Getter
              close={reject}
              retry={retry}
              getter={
                DiyGetter ? <DiyGetter close={reject} retry={retry} /> : null
              }
            />
          )}
        </>
      ) : null}
    </>
  );
};
