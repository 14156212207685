import { useMount } from "ahooks";
import Intercept from "./Intercept";
import Require from "./Require";
import { useState } from "react";

export default ({ game, type, close, callback, superGo, cloud }) => {
  const [able, setAble] = useState();

  useMount(() => {
    if (type == "cloud" && !game.cloud_support_type) {
      close();
      return;
    }
    setAble(true);
  });

  return (
    <>
      {!!able && (
        <Require reject={close}>
          <Intercept
            game={game}
            type={type}
            close={close}
            callback={callback}
            superGo={superGo}
            cloud={cloud}
          />
        </Require>
      )}
    </>
  );
};
