import Image from "../../lib/Image";

export default (props) => {
  const data = props.query;

  return (
    <>
      <div className="service-content-detail">
        {data.type === "1" ? (
          <div className="font">{data.content}</div>
        ) : (
          <div className="qrcode-img">
            <Image src={data.content} alt="" />
            <p style={{ marginTop: "40px", textAlign: "center" }}>
              请您自行保存图片，扫描添加客服后进行咨询
            </p>
          </div>
        )}
      </div>
    </>
  );
};
